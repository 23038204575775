<template>
  <Page title="Browse Samples">
    <v-form @submit.prevent="">
      <v-row dense>
        <v-col>
          <v-text-field
            v-model="options.params.search"
            label="Search"
            clearable
            @input="filterSamples"
            @change="filterSamples"
            @keydown.enter.prevent
            @keydown.esc="clearInputs()"
            @click:clear="clearInputs()"
          ></v-text-field>
        </v-col>

        <!-- <v-col>
          <v-text-field
            v-model="options.params.id_to"
            label="Reference ID To"
            :rules="rules.numeric"
            clearable
            @click:clear="clearInputs()"
          ></v-text-field>
        </v-col> -->
      </v-row>
      <v-row dense>
        <!-- <v-col>
          <v-text-field
            v-model="options.params.slug"
            label="Sample ID"
            clearable
            @click:clear="clearInputs()"
          ></v-text-field>
        </v-col> -->
        <!-- <v-col>
          <v-text-field
            v-model="options.params.shipment_id"
            label="Shipment ID "
            :rules="rules.numeric"
            clearable
            @click:clear="clearInputs()"
          ></v-text-field>
        </v-col> -->
        <!-- <v-col>
          <v-text-field
            v-model="options.params.farm_name"
            label="Farm Name"
            clearable
            @click:clear="clearInputs()"
          ></v-text-field>
        </v-col> -->
      </v-row>
      <v-row dense>
        <!-- <v-col>
          <v-text-field
            v-model="options.params.farm_name"
            label="Farm Name"
            clearable
            @click:clear="clearInputs()"
          ></v-text-field>
        </v-col> -->
        <v-col>
          <v-select
            :items="feed_types"
            label="Feed Type"
            no-data-text="No feed types to select from"
            item-text="title"
            item-value="id"
            v-model="options.params.feed_type_id"
            @input="filterSamples"
            @change="filterSamples"
            clearable
            @click:clear="clearInputs()"
          ></v-select>
        </v-col>
        <!-- <v-col cols="12" sm="6">
          <DatePickerInput
            v-model="options.params.date_collected"
            :datePickerConfig="dateCollected"
            @updateDateField="updateDateField"
          />
        </v-col> -->
        <v-col cols="12" sm="6">
          <DatePickerInput
            v-model="options.params.received_at"
            :datePickerConfig="receivedDate"
            @updateDateField="updateDateField"
          ></DatePickerInput>
        </v-col>
      </v-row>
      <v-row dense>
        <!-- <v-col cols="12" :sm="6">
          <v-text-field
            v-model="options.params.description"
            label="Description"
            clearable
            @click:clear="clearInputs()"
          ></v-text-field>
        </v-col> -->
      </v-row>
      <v-row dense>
        <v-col v-if="hasMultipleClients" cols="12" sm="6">
          <v-select
            :items="clients"
            item-value="id"
            item-text="title_with_id"
            v-model="options.params.client_id"
            label="Client"
            clearable
            @click:clear="clearInputs"
          />
        </v-col>
      </v-row>
      <v-row dense v-if="$route.query.is_pending">
        <v-col>
          <v-checkbox
            v-model="options.params.is_pending"
            label="Is Pending"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row dense v-if="$route.query.created_today">
        <v-col>
          <v-checkbox
            v-model="options.params.created_today"
            label="Created Today"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-form>
    <v-row dense>
      <v-col class="text-right">
        <v-menu transition="slide-y-transition" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              :disabled="!selected.length"
              v-bind="attrs"
              v-on="on"
            >
              Bulk Actions
              {{ selected.length ? `(${selected.length})` : "" }}
            </v-btn>
          </template>
          <v-list>
            <!-- <v-list-item @click="sampleShipmentDialog = true">
              <v-list-item-title>Assign to New Shipment</v-list-item-title>
            </v-list-item> -->
            <v-list-item @click="triggerDownloadReports">
              <v-list-item-title>Create Report</v-list-item-title>
            </v-list-item>
            <v-list-item @click="exportStandardXml">
              <v-list-item-title>Export Standard-XML</v-list-item-title>
            </v-list-item>
            <v-list-item @click="exportXmlData">
              <v-list-item-title>Export XML-Data</v-list-item-title>
            </v-list-item>
            <v-list-item @click="exportAXml">
              <v-list-item-title>Export A-XML</v-list-item-title>
            </v-list-item>
            <v-list-item @click="exportNitCow">
              <v-list-item-title>Export nit-cow-data.txt</v-list-item-title>
            </v-list-item>
            <v-list-item @click="exportFullCsv">
              <v-list-item-title>Create CSV File</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-subheader
              >Custom CSVs:
              <v-spacer></v-spacer>
              <router-link to="/reports/custom-csvs/">
                manage
              </router-link>
            </v-subheader>
            <v-list-item
              v-for="csv in csvs"
              :key="csv.id"
              @click="exportCustomCsv(csv)"
            >
              <v-list-item-title>Export CSV: {{ csv.name }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="!csvs.length" disabled>
              <v-list-item-title>(No Custom CSVs Set Up)</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-data-table
      v-model="selected"
      v-bind="customAttrs"
      :options.sync="options"
      show-select
    >
      <template v-slot:item.id="{ item }">
        <div style="width: 4rem;">{{ item.id }}</div>
      </template>
      <!-- <template v-slot:item.Farms.title="{ item }">
        <span v-if="item.farm">{{ item.farm.title }}</span>
        <span v-else-if="item.source_other"
          >Other: {{ item.source_other }}</span
        >
      </template> -->
      <template v-slot:[`item.reference_code`]="{ item }">
        {{ hexadecimalId(item.slug) }}
      </template>

      <template v-slot:item.farm.title="{ item }">
        <v-tooltip
          bottom
          v-if="
            item?.farm &&
              item?.farm?.title.length &&
              item?.farm?.title.length > 15
          "
          max-width="50%"
        >
          <template v-slot:activator="{ on, attrs }">
            <p
              style="max-width: 8rem;
              text-overflow: ellipsis; 
              white-space: nowrap; 
              overflow: hidden;
              display: flex;
              align-items: center;
              margin: auto;"
              v-bind="attrs"
              v-on="on"
            >
              {{ item?.farm?.title }}
            </p>
          </template>
          <template v-slot:default>
            <div class="custom-tooltip">
              {{ item?.farm?.title }}
            </div>
          </template>
        </v-tooltip>
        <p
          v-else
          style="max-width: 8rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: flex;
          align-items: center;
          margin: auto;"
        >
          {{ item?.farm?.title }}
        </p>
      </template>
      <template v-slot:item.FeedTypes.title="{ item }">
        <!-- <span v-if="item.feed_type">{{ item.feed_type.title }}</span> -->
        <v-tooltip
          bottom
          v-if="
            item?.feed_type &&
              item?.feed_type?.title.length &&
              item?.feed_type?.title.length > 15
          "
          max-width="50%"
        >
          <template v-slot:activator="{ on, attrs }">
            <p
              style="max-width: 8rem;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              display: flex;
              align-items: center;
              margin: auto;"
              v-bind="attrs"
              v-on="on"
            >
              {{ item?.feed_type?.title }}
            </p>
          </template>
          <template v-slot:default>
            <div class="custom-tooltip">
              {{ item?.feed_type?.title }}
            </div>
          </template>
        </v-tooltip>
        <p
          v-else
          style="max-width: 8rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: flex;
          align-items: center;
          margin: auto;"
        >
          {{ item?.feed_type?.title }}
        </p>
      </template>
      <!-- <template v-slot:item.description="{ item }">
        <p
          style="max-width: 8rem; text-overflow: ellipsis; white-space: wrap; text-wrap: wrap;"
        >
          {{ item.description }}
        </p>
      </template> -->

      <!-- <template v-slot:item.description="{ item }">
  <v-tooltip bottom v-if="item.description.length > 60">
    <template v-slot:activator="{ on, attrs }">
      <p
        style="max-width: 8rem; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"
        v-bind="attrs" v-on="on"
      >
        {{ item.description }}
      </p>
    </template>
    <span style="word-wrap: break-word; max-width: 2%; background-color: blue;">{{ item.description }}</span>
  </v-tooltip>
  <p
    v-else
    style="max-width: 8rem; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"
  >
    {{ item.description }}
  </p>
</template> -->
      <template v-slot:item.created="{ item }">
        <div style="width: 7rem;">
          {{ formatDate(item.created) }}
        </div>
      </template>
      <template v-slot:item.description="{ item }">
        <v-tooltip
          bottom
          v-if="
            item.description &&
              item.description.length &&
              item.description.length > 20
          "
          max-width="50%"
        >
          <template v-slot:activator="{ on, attrs }">
            <p
              style="max-width: 6rem; 
              text-overflow: ellipsis; 
              white-space: nowrap; 
              overflow: hidden;
              display: flex;
              align-items: center;
              margin: auto;"
              v-bind="attrs"
              v-on="on"
            >
              {{ item.description }}
            </p>
          </template>
          <template v-slot:default>
            <div class="custom-tooltip">
              {{ item.description }}
            </div>
          </template>
        </v-tooltip>
        <p
          v-else
          style="max-width: 8rem; 
          text-overflow: ellipsis; 
          white-space: nowrap;
          overflow: hidden;
          display: flex;
          align-items: center;
          margin: auto;"
        >
          {{ item.description }}
        </p>
      </template>

      <template v-slot:item.date_collected="{ item }">
        <div style="width: 7rem;">
          <span v-if="item.date_collected">{{
            formatDate(item.date_collected)
          }}</span>
        </div>
      </template>
      <template v-slot:item.TestPackages="{ item }">
        <div v-if="item.test_packages.length > 3">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <ul>
                <li v-for="index in 3" :key="index">
                  <span v-bind="attrs" v-on="on"
                    >{{ item.test_packages[index].title
                    }}<span v-if="index !== 3">,</span></span
                  >
                </li>
                <li>
                  <span v-bind="attrs" v-on="on"> ... </span>
                </li>
              </ul>
            </template>
            <ul>
              <li
                v-for="(test_package, index) in item.test_packages"
                :key="index"
              >
                {{ test_package.title
                }}<span v-if="index < item.test_packages.length">,</span>
              </li>
            </ul>
          </v-tooltip>
        </div>
        <div v-else>
          <ul>
            <li
              v-for="(test_package, index) in item.test_packages"
              :key="index"
            >
              {{ test_package.title
              }}<span v-if="index < item.test_packages.length - 1">,</span>
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:item.TestComponents="{ item }">
        <div v-if="item.test_components.length > 3">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <ul>
                <li v-for="index in 3" :key="index">
                  <span v-bind="attrs" v-on="on"
                    >{{ item.test_components[index].title
                    }}<span v-if="index !== 3">,</span></span
                  >
                </li>
                <li>
                  <span v-bind="attrs" v-on="on"> ... </span>
                </li>
              </ul>
            </template>
            <ul>
              <li
                v-for="(test_component, index) in item.test_components"
                :key="index"
              >
                {{ test_component.title
                }}<span v-if="index < item.test_components.length">,</span>
              </li>
            </ul>
          </v-tooltip>
        </div>
        <div v-else>
          <ul>
            <li
              v-for="(test_component, index) in item.test_components"
              :key="index"
            >
              {{ test_component.title
              }}<span v-if="index < item.test_components.length - 1">,</span>
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:item.ShipmentSampleReceived.received_at="{ item }">
        <div style="width: 6rem;">
          <div v-if="item.shipments_samples_received">
            {{ formatDate(item.shipments_samples_received.received_at) }}
          </div>
        </div>
      </template>
      <template v-slot:item.sample_status.title="{ item }">
        <div style="width: 6rem;">
          {{ item.sample_status.title }}
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <div style="min-width: 5rem;">
          <span v-if="!isLocked(item)">
            <v-tooltip
              top
              z-index="2"
              v-if="
                item.sample_status.title === 'Shipped' ||
                  item.sample_status.title === 'New'
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <router-link
                  :to="{
                    name: 'New Sample Images',
                    params: { sampleId: item.slug }
                  }"
                >
                  <v-icon class="action-icon" v-bind="attrs" v-on="on">
                    mdi-image
                  </v-icon>
                </router-link>
              </template>
              <span>Sample Images</span>
            </v-tooltip>
            <v-tooltip top z-index="2" v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="action-icon"
                  v-bind="attrs"
                  color="grey lighten-1"
                  v-on="on"
                >
                  mdi-image
                </v-icon>
              </template>
              <span>Received samples can not be edited</span>
            </v-tooltip>

            <v-tooltip
              top
              z-index="2"
              v-if="
                item.sample_status.title === 'Shipped' ||
                  item.sample_status.title === 'New'
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <router-link
                  :to="{
                    name: 'New Sample Edit',
                    params: { sampleId: item.id }
                  }"
                >
                  <v-icon class="action-icon" v-bind="attrs" v-on="on">
                    mdi-pencil
                  </v-icon>
                </router-link>
              </template>
              <span>Edit Sample</span>
            </v-tooltip>
            <v-tooltip top z-index="2" v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  style="margin-left: 8px;"
                  class="action-icon"
                  v-bind="attrs"
                  v-on="on"
                  color="grey lighten-1"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Received samples can not be edited</span>
            </v-tooltip>

            <v-tooltip top z-index="2">
              <template v-slot:activator="{ on, attrs }">
                <router-link
                  :to="{
                    name: 'New Sample View',
                    params: { sampleId: item.id }
                  }"
                >
                  <v-icon class="action-icon" v-bind="attrs" v-on="on">
                    mdi-eye
                  </v-icon>
                </router-link>
              </template>
              <span>View Sample</span>
            </v-tooltip>
            <span>
              <v-tooltip top z-index="2">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="action-icon"
                    @click.stop="() => toggleItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    :color="item.is_active ? 'error' : 'success'"
                  >
                    <!-- {{ item.is_active ? "mdi-cancel" : "mdi-check" }} -->
                  </v-icon>
                </template>
                <!-- <span>{{ item.is_active ? "Cancel" : "Activate" }} Sample</span> -->
              </v-tooltip>
            </span>
          </span>
          <span>
            <v-tooltip top z-index="2">
              <template v-slot:activator="{ on, attrs }">
                <span class="mx-2">
                  <v-icon
                    @click.stop="() => sampleStatusModal(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-progress-clock
                  </v-icon>
                </span>
              </template>
              <span>Sample Status</span>
            </v-tooltip>
            <v-tooltip top v-if="item.sample_status_id >= 8">
              <template v-slot:activator="{ on, attrs }">
                <span class="mx-2">
                  <button @click="getUrl(item.id)" target="_blank">
                    <v-icon v-bind="attrs" v-on="on">
                      mdi-file
                    </v-icon>
                  </button>
                </span>
              </template>
              <span>Download Report PDF</span>
            </v-tooltip>
          </span>
        </div>
      </template>
    </v-data-table>

    <SampleToggleDialog
      v-model="toggleSampleItem"
      v-on:sample-update="onSampleUpdate"
    />
    <v-dialog v-model="sampleShipmentDialog" width="600">
      <SampleShipment
        :samples="selected"
        v-on:close="sampleShipmentDialogClose"
      />
    </v-dialog>
    <v-dialog
      v-model="sample_status.active"
      content-class="status-dialog"
      width="900"
    >
      <SampleStatus
        v-if="sample_status.item !== false"
        v-model="sample_status.item"
        :sample_statuses="this.sample_statuses"
        v-on:close-dialog="() => sampleStatusModal(false)"
        v-on:refresh="() => refresh()"
      />
    </v-dialog>
  </Page>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Page from "@/components/layout/Page";
import SampleToggleDialog from "@/components/samples/components/SampleToggleDialog";
import SampleShipment from "@/components/samples/components/SampleShipment";
import SampleStatus from "@/components/samples/components/SampleStatus";
import dataTablePagination from "@/mixins/dataTablePagination";
import DatePickerInput from "@/components/formElements/DatePickerInput";
import dayjs from "dayjs";
import rules from "@/utils/rules";
import { getBaseUrl } from "@/utils";

export default {
  name: "SamplesBrowse",
  components: {
    Page,
    SampleToggleDialog,
    SampleShipment,
    DatePickerInput,
    SampleStatus
  },
  mixins: [dataTablePagination],
  data() {
    return {
      loading: false,
      rules: {
        numeric: [rules.numeric]
      },
      pdfUrl: "sample-pdf-reports",
      headers: [
        { text: "Ref ID", value: "id", filterable: false },
        { text: "Lab ID", value: "slug" },
        { text: "Reference Code", value: "reference_code", sortable: false },
        { text: "Farm Name", value: "farm.title", sortable: false },
        { text: "Feed Type", value: "FeedTypes.title" },
        { text: "Created At", value: "created" },
        { text: "Description", value: "description", sortable: false },
        { text: "Test Packages", value: "TestPackages", sortable: false },
        { text: "Test Components", value: "TestComponents", sortable: false },
        { text: "Sampled Date", value: "date_collected", sortable: true },
        {
          text: "Received Date",
          value: "ShipmentSampleReceived.received_at",
          dataType: "Date"
        },
        { text: "Status", value: "sample_status.title" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      options: {
        itemsPerPage: 15,
        params: {
          // id_from: "",
          // id_to: "",
          // farm_name: "",
          feed_type_id: null,
          // description: "",
          // client_id: "",
          // date_collected: "",
          received_at: "",
          // slug: "",
          search: "",
          is_pending: this.$route.query.is_pending,
          created_today: this.$route.query.created_today
        }
      },
      selected: [],
      toggleSampleItem: false, // false|sample
      sampleShipmentDialog: false,
      receivedDate: {
        label: "Arrived Date",
        field: "received_at"
      },
      dateCollected: {
        label: "Sampled Date",
        field: "date_collected"
      },
      sample_status: {
        active: false,
        item: false
      }
    };
  },
  computed: {
    ...mapState("samples", { samples: "items" }),
    ...mapState("csvs", { csvs: "items" }),
    ...mapState("clients", { clients: "items" }),
    ...mapGetters("baseData", [
      "getInvoicedStatusId",
      "feed_types",
      "sample_statuses"
    ]),
    ...mapGetters("clients", ["hasMultipleClients"]),
    filteredHeaders() {
      return this.headers.filter(this.filterHeaders);
    },
    adjustedAttrs() {
      return { ...this.defaultAttrs, headers: this.filteredHeaders };
    },
    customAttrs() {
      return {
        ...this.defaultAttrs,
        items: this.items
      };
    }
  },
  methods: {
    ...mapActions(["notify"]),
    ...mapActions("samples", ["getPaginated", "getFile"]),
    ...mapActions("csvs", ["getCsvs"]),
    ...mapActions("baseData", ["getSampleStatuses", "getFeedTypes"]),
    ...mapActions("reports", [
      "getReports",
      "downloadReports",
      "downloadSampleReport"
    ]),
    hexadecimalId(slug) {
      const lastSixChars = slug.slice(-6);
      const decimalValue = Number(lastSixChars);
      return decimalValue.toString(16).toUpperCase();
    },
    async getUrl(id) {
      try {
        // Retrieve the Bearer token from localStorage
        const token = localStorage.getItem("auth_token_default");

        // If no token is found, redirect to login
        if (!token) {
          window.location.href = getBaseUrl();
          return;
        }

        // Construct the URL by adding the Bearer token as a query parameter
        const pdfUrl = `${getBaseUrl()}/${
          this.pdfUrl
        }/${id}?render=pdf&token=${encodeURIComponent(token)}`;

        // Open the URL in a new tab
        window.open(pdfUrl, "_blank");
      } catch (error) {
        // Handle errors, such as 401 Unauthorized or 500 Internal Server Error
        if (error.response && error.response.status === 401) {
          alert("Your session has expired. Redirecting to login.");
          window.location.href = getBaseUrl();
        } else if (error.response && error.response.status === 500) {
          alert("Server error: Unable to generate the PDF.");
        } else {
          console.error(
            "Error opening PDF:",
            error.response ? error.response.data : error.message
          );
          alert("An error occurred while opening the PDF.");
        }
      }
    },
    downloadPDF(itemId) {
      const url = this.getUrl(itemId);

      // Create an anchor element and programmatically trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank"; // open in new tab
      link.download = `report-${itemId}.pdf`; // set the download attribute

      // Append the link to the body, trigger the click and remove the element
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    filterSamples() {
      // let searchTerm = this.search.toLowerCase().trim();
      // if (
      //   this.items.some(item =>
      //     item.id
      //       .toString()
      //       .toLowerCase()
      //       .trim()
      //       .includes(searchTerm)
      //   )
      // ) {
      //   this.options.params = {};
      //   this.options.params.id_from = searchTerm;
      // } else if (
      //   this.items.some(item =>
      //     item.id
      //       .toString()
      //       .toLowerCase()
      //       .trim()
      //       .includes(searchTerm)
      //   )
      // ) {
      //   this.options.params = {};
      //   this.options.params.id_to = searchTerm;
      // } else if (
      //   this.items.some(item =>
      //     item.farm.title
      //       .toLowerCase()
      //       .trim()
      //       .includes(searchTerm)
      //   )
      // ) {
      //   this.options.params = {};
      //   this.options.params.farm_name = searchTerm;
      // } else if (
      //   this.items.some(
      //     item =>
      //       item.description &&
      //       item.description
      //         .toLowerCase()
      //         .trim()
      //         .includes(searchTerm)
      //   )
      // ) {
      //   this.options.params = {};
      //   this.options.params.description = searchTerm;
      // } else if (
      //   this.items.some(item =>
      //     item.slug
      //       .toLowerCase()
      //       .trim()
      //       .includes(searchTerm)
      //   )
      // ) {
      //   this.options.params = {};
      //   this.options.params.slug = searchTerm;
      // } else if (searchTerm == "") {
      //   this.options.params = {};
      // } else {
      //   this.items = [];
      // }

      this.getPaginated();
    },
    ...mapMutations(["setLoading"]),
    filterHeaders(header) {
      const xs = ["Ref ID", "Sample ID", "Farm Name", "Description", "Actions"];
      if (this.$vuetify.breakpoint.xsOnly) return xs.includes(header.text);
      else if (this.$vuetify.breakpoint.smOnly)
        return [...xs, "Feed Type"].includes(header.text);
      else return true;
    },
    triggerDownloadReports() {
      if (!this.isAllSelectedReleased(this)) {
        return;
      }

      this.setLoading({
        message: "Downloading Report...",
        opacity: 0.5,
        overlay: true
      });
      this.downloadReports({
        sample_ids: this.selected.map(item => item.id)
      }).then(() => this.setLoading({}));
    },
    isLocked(item) {
      return (
        item &&
        item.sample_status_id &&
        item.sample_status_id === this.getInvoicedStatusId
      );
    },
    // Ensure all selected samples are released or generate errors and return false
    isAllSelectedReleased(items) {
      const selectedNotReceived = items.selected.filter(item => {
        if (item.sample_status_id < 8) {
          return item.id;
        }
      });

      if (selectedNotReceived.length > 0) {
        this.notify({
          message:
            "Sample #" +
            selectedNotReceived.map(item => item.id).join(", ") +
            " is not released."
        });
      } else {
        return true;
      }
    },
    sampleShipmentDialogClose(update = false) {
      this.sampleShipmentDialog = false;
      if (update) {
        this.getPaginatedDebounced();
      }
    },
    toggleItem(item) {
      // Set the toggle item (false|sample)
      this.toggleSampleItem = item;
    },
    onSampleUpdate(res) {
      if (res.success && res.data) {
        // Update the component item data
        const i = this.items.findIndex(item => item.id === res.data.id);
        Vue.set(this.items, i, {
          ...this.items[i],
          ...res.data
        });
      }
      // Update loading status
      this.loading = res.loading !== undefined ? res.loading : false;
    },
    editItem(item) {
      this.$router.push(`/samples/sample-wizard/${item.id}`);
    },
    formatDate(value) {
      return value ? dayjs(String(value)).format("MM/DD/YYYY") : "";
    },
    updateDateField(field, value) {
      this.options.params[field] = value;
    },
    getSelectedForUrl() {
      const params = { samples: this.selected.map(item => item.id) };
      return Object.keys(params)
        .filter(key => params[key])
        .map(key => key + "=" + params[key])
        .join("&");
    },
    exportStandardXml() {
      if (!this.isAllSelectedReleased(this)) {
        return;
      }
      this.getFile({
        url: "/samples/export/standard-xml",
        data: this.getSelectedForUrl(),
        type: "application/xml"
      });
    },
    exportXmlData() {
      if (!this.isAllSelectedReleased(this)) {
        return;
      }
      this.getFile({
        url: "/samples/export/xml-data",
        data: this.getSelectedForUrl(),
        type: "application/xml"
      });
    },
    exportAXml() {
      if (!this.isAllSelectedReleased(this)) {
        return;
      }
      this.getFile({
        url: "/samples/export/a-xml",
        data: this.getSelectedForUrl(),
        type: "application/xml"
      });
    },
    exportNitCow() {
      if (!this.isAllSelectedReleased(this)) {
        return;
      }
      this.getFile({
        url: "/samples/export/nit-cow-data",
        data: this.getSelectedForUrl(),
        type: "text/csv"
      });
    },
    exportFullCsv() {
      if (!this.isAllSelectedReleased(this)) {
        return;
      }
      this.getFile({
        url: "/samples/export/csv",
        data: this.getSelectedForUrl(),
        type: "text/csv"
      });
    },
    exportCustomCsv(csv) {
      this.getFile({
        url: "/samples/export/custom-csv/" + csv.id + "/",
        data: this.getSelectedForUrl(),
        type: "text/csv"
      });
    },
    sampleStatusModal(item = false) {
      this.sample_status.active = item !== false;
      this.sample_status.item = item;
    },
    triggerDownloadSampleReport(item) {
      this.setLoading({
        message: "Downloading Report...",
        opacity: 0.5,
        overlay: true
      });
      this.downloadSampleReport({
        id: item.id
      }).then(() => this.setLoading({}));
    },
    filterShipments: function(shipmentId) {
      this.options.params.shipment_id = shipmentId;
      this.getPaginatedDebounced();
    },
    clearInputs() {
      this.options.params.search = "";
      // this.options.params = {};
      this.getPaginatedDebounced();
      this.search = "";
    }
  },
  mounted() {
    this.loading = true;
    Promise.all([
      this.getSampleStatuses(),
      this.getFeedTypes(),
      this.getCsvs()
    ]).then(() => (this.loading = false));
  }
};
</script>

<style>
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 80% !important;
}
</style>

<style lang="scss" scoped>
.v-data-table {
  // Icons with hyperlinks need some spacing so the underlines don't blend
  tr td:last-child a {
    margin: 0 2px;
  }
}

.action-icon {
  margin-top: 2px;
  margin-bottom: 4px;
}
ul {
  list-style-type: none;
}
li {
  cursor: default;
}

.custom-tooltip {
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word; /* Ensure long words break correctly */
  padding: 8px; /* Optional: Add some padding for better readability */
  background: rgba(0, 0, 0, 0); /* Optional: Set a background color */
  border: none; /* Optional: Add a border for better distinction */
  text-align: center;
  margin: auto;
  border-radius: 4px; /* Optional: Rounded corners */
}
</style>
